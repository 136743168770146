import React, { memo, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useLocalization } from '@progress/kendo-react-intl';
import { useSelector, useDispatch } from 'react-redux';
import loadable from "@loadable/component";
import DrawMetaTags from 'app-styled/metatags';
import { getProfileData } from "../profile.actions";
import { getProfileDataFromReducer } from '../profile.selector';
import * as SC from '../profile.styled';
import OnlineBookingComponent from "../onlineBooking";
import FaqComponent from "../faq";
import { REGION_TYPES } from '@core-const';
import { regionFinder } from 'app-core/constant/utills';

const MainProfileComponent = loadable(
    () => import(/*webpackChunkName: "pkg_____dentist_profile"*/ "../mainProfile")
  );
const LocationComponent = loadable(
    () => import(/*webpackChunkName: "pkg_____dentist_profile"*/ "../location")
  );
const TreatmentsComponent = loadable(
    () => import(/*webpackChunkName: "pkg_____dentist_profile"*/ "../treatments")
  );
const PresentationComponent = loadable(
    () => import(/*webpackChunkName: "pkg_____dentist_profile"*/ "../presentation")
  );
const AllReviewsComponent = loadable(
    () => import(/*webpackChunkName: "pkg_____dentist_profile"*/ "../allReviews")
  );
const NhsDentistComponent = loadable(
    () => import(/*webpackChunkName: "pkg_____dentist_profile"*/ "../nhsDentitst")
  );
const Footer = loadable(
    () => import(/*webpackChunkName: "pkg_____dentist_profile"*/ "../../../modules/footer")
  );

const ProfileContent = () => {
    const data = useSelector(getProfileDataFromReducer);
    const localization = useLocalization();
    const location = useLocation()
    const [profileData, setProfileData] = useState(data)
    const [showEdit, setShowEdit] = useState(null)
    const dispatch = useDispatch();
    let status = location.pathname.split('/')[1];
    let { id } = useParams();

    const keywords = localization.toLanguageString('smylor,smylor dentist', 'dentist, Dentist, Dentist office, Dentist near me, Emergency dentist, Pediatric dentist, Family dentist, Kids dentist, Dental clinic emergency, dental, patient, online booking, dental clinic near me');
    const metaTitle = localization.toLanguageString('meta_profilepage_title', 'Dental Treatments Marketplace | Smylor');;
    const metaDescription =  `${data?.Practice} details`; 
    const metaImageUrl = data?.OrganizationLogo ;
    const metaPageUrl = `${process.env.REACT_APP_ROOT_URL}/profile/${data?.OrganizationID}`;
    
    useEffect(() => {
        dispatch(getProfileData({ organizationId: decodeURI(id) }));
    }, []);

    useEffect(() => {
        if (status == 'profile') {
            setShowEdit(false)
        } else if (status == 'myprofile') {
            setShowEdit(true)
        }
    }, [status]);

    useEffect(() => {
        setProfileData(data)
    }, [JSON.stringify(data)]);

    return (
        <>
            <DrawMetaTags description={metaDescription} imageUrl={metaImageUrl} title={metaTitle} pageUrl={metaPageUrl} keywords={keywords}/>
            <MainProfileComponent data={profileData} showEdit={showEdit}/>
            <LocationComponent data={profileData} showEdit={showEdit} />
            {(regionFinder() === REGION_TYPES.NET || regionFinder() === REGION_TYPES.UK) && <NhsDentistComponent showEdit={showEdit} data={profileData} />}
            <TreatmentsComponent showEdit={showEdit} data={profileData} id={id} />
            <PresentationComponent showEdit={showEdit} data={profileData} />     
            {/* <OnlineBookingComponent/> */}
            {!showEdit && profileData && <AllReviewsComponent data={profileData}/>}
            {/* {!showEdit && <FaqComponent />} */}

            {!showEdit &&
                <SC.FooterContainer >
                    <Footer />
                </SC.FooterContainer>
            }
        </>
    )

}

export default memo(ProfileContent);