import { ReviewsDataCommon, ReviewsRequestData } from "@core-interface";

export const GET_REVIEWS_DATA = "GET_REVIEWS_DATA";
export const getProfileReviewsData = (payload: ReviewsRequestData) => ({
  type: GET_REVIEWS_DATA,
  payload,
});

export const SET_REVIEWS_DATA = "SET_REVIEWS_DATA";
export const setProfileReviewsData = (payload: ReviewsDataCommon) => ({
  type: SET_REVIEWS_DATA,
  payload,
});
