import React, { memo } from "react";

import * as SC from "./profile.styled";
import ProfileContent from "./ProfileContent";

const ProfilePage = () => {
  return (
    <SC.MainWrapper>
      <ProfileContent />
    </SC.MainWrapper>
  );
};

export default memo(ProfilePage);
