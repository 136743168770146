import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LocalizationProvider } from "@progress/kendo-react-intl";
import "./App.css";
import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import MainComponet from "./modules/main";
// import GlobalStyle from "./core/styled/globalStyles";
import { getTheme } from "./core/styled/theme";
import { getLocalizationData, setMapLoaded } from "@core-action";
import {
  getLocalizationLanguage,
  getLocalizationList,
} from "./modules/localization/localization.selector";
import loadable from "@loadable/component";

const GlobalStyle = loadable(
  () => import(/*webpackChunkName: "pkg_____common"*/ "./core/styled/globalStyles")
);

declare global {
  interface Window {
    initMap: any;
  }
}

let win: any = window;

function App() {
  const dispatch = useDispatch();
  const languageList = useSelector(getLocalizationList);

  useEffect(() => {
    dispatch(getLocalizationData());
  }, []);

  useEffect(() => {
    win.initMap = () => {
      dispatch(setMapLoaded(true));
    };
    const gmapScriptEl = document.createElement(`script`);
    gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAjmXiD-nVEaLyalBEB8mUDtLkvCtjID6I&libraries=places&callback=initMap`;
    document
      .querySelector(`body`)
      .insertAdjacentElement(`beforeend`, gmapScriptEl);
  }, []);

  useEffect(() => {
    if (Object.keys(languageList).length > 0) {
      const loader = document.getElementById('loader')
      loader.style.display = 'none';
    }
    console.log('v_4.4.9');
  }, [JSON.stringify(languageList)]);

  return (
    <ThemeProvider theme={getTheme()}>
      <LocalizationProvider language={useSelector(getLocalizationLanguage)}>
        <GlobalStyle />
        <BrowserRouter>
          {Object.keys(languageList).length > 0 && <MainComponet />}
        </BrowserRouter>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
