import { EmailVerificationPayload, NewPatientProps } from '@core-interface';

export const GET_AUTHORIZE = 'GET_AUTHORIZE';
export const getAuthorize = (payload: string) => ({ type: GET_AUTHORIZE, payload });

export const SET_TOKEN = 'SET_TOKEN';
export const setToken = (payload: string) => ({ type: SET_TOKEN, payload });

export const NEW_USER = 'NEW_USER';
export const newUser = (payload: NewPatientProps) => ({ type: NEW_USER, payload });

export const REGISTER_NEW_USER = 'REGISTER_NEW_USER';
export const registerNewUser = (payload: NewPatientProps) => ({ type: REGISTER_NEW_USER, payload });

export const SET_USER = 'SET_USER';
export const setUser = (payload: string) => ({ type: SET_USER, payload });

export const SET_LOGING_STATUS = 'SET_LOGING_STATUS';
export const setLogingStatus = (payload: any) => ({ type: SET_LOGING_STATUS, payload });

export const SET_EMERGENCY_USER = 'SET_EMERGENCY_USER';
export const setEmergencyUser = (payload: string) => ({ type: SET_EMERGENCY_USER, payload });

export const GET_EMERGENCY_USER = 'GET_EMERGENCY_USER';
export const getEmergencyUser = (payload: string) => ({ type: GET_EMERGENCY_USER, payload });

export const SET_TREATMENT_TYPE = 'SET_TREATMENT_TYPE';
export const setTreatmentType = (payload: any) => ({ type: SET_TREATMENT_TYPE, payload });

export const SET_DENTIST_COUNT = 'SET_DENTIST_COUNT';
export const setDentistCount = (payload: any) => ({ type: SET_DENTIST_COUNT, payload });

export const GET_DENTIST_COUNT = 'GET_DENTIST_COUNT';
export const getDentistCount = (payload: any) => ({ type: GET_DENTIST_COUNT, payload });

export const GET_VALIDATE_EMAIL = 'GET_VALIDATE_EMAIL';
export const getValidateEmail = (payload: any) => ({ type: GET_VALIDATE_EMAIL, payload });

export const SET_VALIDATE_EMAIL = 'SET_VALIDATE_EMAIL';
export const setValidateEmail = (payload: any) => ({ type: SET_VALIDATE_EMAIL, payload });

export const GET_SUCCESS_STATUS = 'GET_SUCCESS_STATUS';
export const getSuccessStatus = (payload: boolean) => ({ type: GET_SUCCESS_STATUS, payload });

export const SET_SUCCESS_STATUS = 'SET_SUCCESS_STATUS';
export const setSuccessStatus = (payload: boolean) => ({ type: SET_SUCCESS_STATUS, payload });

export const GET_EMAIL_VALIDATION_LINK = 'GET_EMAIL_VALIDATION_LINK';
export const getEmailValidationLink = (payload: EmailVerificationPayload) => ({ type: GET_EMAIL_VALIDATION_LINK, payload });

export const SET_EMAIL_VALIDATION_LINK = 'SET_EMAIL_VALIDATION_LINK';
export const setEmailValidationLink = (payload: boolean) => ({ type: SET_EMAIL_VALIDATION_LINK, payload });

export const RESET_EMAIL_VERIFY_DETAILS = 'RESET_EMAIL_VERIFY_DETAILS';
export const resetEmailVerificationDetails = () => ({ type: RESET_EMAIL_VERIFY_DETAILS });

export const SUCCESS_STATUS_RESET = 'SUCCESS_STATUS_RESET';
export const resetUserRegisterSucessStatus = (payload: boolean) => ({ type: SUCCESS_STATUS_RESET, payload });
