import React, { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { MyProfileNavigationType, Session, Settings } from '@core-interface'
import { myProfileNavigation } from './constant';
import { logoutUser, getSession, loginUrl } from '@core-const';
import { useDispatch, useSelector } from 'react-redux';
import { getSettingsFromStore } from 'src/core/core.selectors';
import { setSettings } from '@core-action';
import { Message } from '@localization';

import * as SC from './myProfile.styled';

const MyProfilePage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let location = useLocation()
    const status = location.pathname.split('/')[2];
    const [showNavi, setShowNavi] = useState(false);
    const session: Session = getSession();
    const settings: Settings = useSelector(getSettingsFromStore);
    if (!session) {
        localStorage.setItem('redirectUrl', location.pathname);
        loginUrl();
    }

    const navigationPath = useCallback(({ url, id }: any) => {
        if (id === 'logout')
            logoutUser();
        else if (id == 'my_chats')
            window.open(url, "_blank");
        else {
            if (settings.isMobile)
                dispatch(setSettings({ showNavigation: false }))
            navigate(url, { replace: true });
        }
    }, [navigate]);

    useEffect(() => {
        setShowNavi(settings.showNavigation);
    }, [settings.showNavigation]);

    return (
        <SC.MyProfileNavigatorContainer>
            <SC.MyProfileNavigationList md={3} lg={2} className={showNavi ? "show" : ''}>
                {myProfileNavigation(session.role, session.userId).map((profileItem: MyProfileNavigationType) =>
                    <SC.MyProfileNavigatorListItem
                        key={profileItem.id}
                        onClick={() => navigationPath(profileItem)}
                        selected={profileItem.url.split('/')[0] == status ? true : false}
                    >
                        <Message messageKey={profileItem.id} defaultMessage={profileItem.text} />
                    </SC.MyProfileNavigatorListItem>
                )}
            </SC.MyProfileNavigationList>
            <SC.MyProfileNavigationBody md={9} lg={10} >
                <Outlet />
            </SC.MyProfileNavigationBody>
        </SC.MyProfileNavigatorContainer>
    );
}

export default memo(MyProfilePage);
