
const readParams = (searchParams: any) => {

  const searchParam = {
    Culture: localStorage.getItem('lang'),
    treatmentType: searchParams.get("treatment") || searchParams.get("treatmentType"),
    lat: searchParams.get("lat"),
    lng: searchParams.get("lng"),
    location: searchParams.get("location"),
    searchText: searchParams.get("searchText"),
    review: {
      practiceMin: searchParams.get("practiceMin") || 0,
      practiceMax: searchParams.get("practiceMax") || 0,
      treatmentMax: searchParams.get("treatmentMax") || 0,
      treatmentMin: searchParams.get("treatmentMin") || 0
    },
    affordability: { priceMax: searchParams.get("priceMax") || 0, priceMin: searchParams.get("priceMin") || 0 },
    dentist: {
      distance: searchParams.get("distance"),
      onlineBooking: searchParams.get("onlineBooking") ? true : null,
      paymentPlans: searchParams.get("paymentPlans") ? true : null,
      onlineChat: searchParams.get("onlineChat") ? true : null,
      nhsDoctor: searchParams.get("nhsDoctor") ? true : null,
      patientAcceptTypes: searchParams.get("patientAcceptTypes") ? searchParams.get("patientAcceptTypes") : null
    }
  };
  return searchParam;
};

const sortParams = (filter: any, navigate: any) => {
  let obj = {
    ...filter.review, ...filter.affordability, ...filter.dentist,
    treatmentType: filter.treatmentType,
    lat: filter.lat,
    lng: filter.lng,
    location: filter.location,
    searchText: filter.searchText
  };
  const queryString = Object.keys(obj).map(key => obj[key] ? key + '=' + obj[key] : null).filter(item => item).join('&');
  navigate({ pathname: '/list', search: `?${queryString}` });
};

export { readParams, sortParams }