import React from "react";
import loadable from "@loadable/component";
const Header = loadable(
  () => import(/*webpackChunkName: "pkg_____header"*/ "../header")
);

const MainHeader = ({ children, showPreHeader }) => {
  return (
    <>
      <Header preHeader={showPreHeader} />
      {children}
    </>
  );
};

export default MainHeader;
