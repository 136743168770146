import styled, { css } from "styled-components";
import { device } from "@core-const";
import { MyProfileNavigationListStyledProps } from "@core-interface";
import { FlexRow, FlexCenter } from 'app-styled/flex';
import { LinkButton, LinkButtonSmall } from "app-styled/link";

export const MyProfileNavigatorContainer = styled(FlexRow)`
  margin: 0;
  ${({ theme }) => css`
    @media${theme.tablet} {
      width: auto;
    }
  `}
`;
export const MyProfileNavigationList = styled.div`
  padding: 0;
  margin-top: 71px;
  height: calc(100vh - 73px);
  width: 300px;
  ${({ theme }) => css`
    background-color: ${theme["thm-secondary"]};
    @media ${device.laptop} {
      width: 175px;
    }
    @media ${device.tablet} {
      position: absolute;
      left: -100%;
      margin-top: 70px;
      height: calc(100vh - 72px);
      width: 100%;
      &.show {
        left: 0px;
        z-index: 2;
      }
    }
  `}
`;

export const MyProfileNavigationBody = styled.div`
  margin-top: 71px;
  height: calc(100vh - 73px);
  width: calc(100vw - 300px);
  display: flex;
  justify-content: center;
  overflow-y: auto;
  padding-right: 0;
  @media ${device.laptop} {
    width: calc(100vw - 175px);
  }
  @media ${device.tablet} {
    padding: 0px;
    width: 100%;
  }
`;

export const MyProfileNavigatorListItem = styled.div<MyProfileNavigationListStyledProps>`
  font-weight: 500 !important;
  text-align: left;
  ${({ theme }) => css`
    padding: ${theme["padding-dropMenu"]} 20px;
    border-bottom: 1px solid ${theme["thm-secondary"]};
    background-color: ${theme["thm-secondary"]};
    color: #ffffff;
    &:hover {
      background-color: rgb(${theme["thm-base-rgb"]}, 0.5);
      cursor: pointer;
      color: #000000;
    }
  `}
  ${({ theme, selected }) =>
    selected &&
    css`
      color: ${theme.colorBtnText};
      background-color: rgb(${theme["thm-base-rgb"]}, 0.5);
    `}
`;

export const LoadingWrapper = styled(FlexCenter)`
  ${({ theme }) =>
    css`
      font-size: ${theme["fontSize-elementSmall"]};
      color: ${theme["color-dark"]};
      margin: ${theme["thm-space-xl"]};
      font-family: ${theme["fontFamily-regular"]};
    `}
`;
export const ReviewButtonContainer = styled(FlexRow)`
  justify-content: end;
  /* ${({ theme }) =>
    css`
      font-size: ${theme["fontSize-elementSmall"]};
      color: ${theme["color-dark"]};
      margin: ${theme["thm-space-xl"]};
      font-family: ${theme["fontFamily-regular"]};
    `} */
`;
export const ReviewButton = styled(LinkButtonSmall)`
  margin-right: 0;
  ${({ theme }) => css`
    padding: ${theme["thm-space-sm"]} ${theme["thm-space-xl"]};
  `}
`;
