import styled, { css } from "styled-components";
import { device } from "@core-const";
import { Page } from "../../core/styled/components/containers";
import { FlexCol, FlexRow } from "../profile/profile.styled";
import { Para, TitleH3, TitleH4 } from "app-styled/title";

export const PrivacyWrapper = styled(Page)`
  margin-top: 117px;
  @media ${device.tablet} {
    margin-top: 80px;
  }
`;
export const PrivacyContainer = styled(FlexRow)``;
export const ConditionContainer = styled(FlexCol)`
  ${({ theme }) => css`
    padding: ${theme["thm-space-lg"]} 0;
  `}
`;
export const MainTitile = styled(TitleH3)`
  ${({ theme }) => css`
    padding: ${theme["thm-space-md"]} 0;
  `}
`;
export const Titile = styled(TitleH4)`
  ${({ theme }) => css`
    /* padding: ${theme["thm-space-sm"]} 0; */
  `}
`;
export const ParaContainer = styled(Para)`
  ${({ theme }) => css`
    /* padding: ${theme["thm-space-sm"]} 0; */
  `}
`;
export const ParaSmallDetails = styled(Para)`
  ${({ theme }) => css`
    margin-bottom: 0;
  `}
`;
