import loadable from "@loadable/component";

const RichTextComponent = loadable(
  () => import(/*webpackChunkName: "pkg_____formComponent"*/ "./richTextComponent")
);
const DateTimePickerInput = loadable(
  () => import(/*webpackChunkName: "pkg_____formComponent"*/ "./dateTimePicker")
);
const DatePiker = loadable(
  () => import(/*webpackChunkName: "pkg_____formComponent"*/ "./datePiker")
);
const CustumLocationSearch = loadable(
  () => import(/*webpackChunkName: "pkg_____formComponent"*/ "./locationSearchFormComponent")
);
const CustumDefaultDentistSearch = loadable(
  () => import(/*webpackChunkName: "pkg_____formComponent"*/ "./defaultDentistSearchFormComponent")
);
const CustumDefaultReviewDentistSearch = loadable(
  () => import(/*webpackChunkName: "pkg_____formComponent"*/ "./dentistSearchFormComponent")
);
const TimePickerInput = loadable(
  () => import(/*webpackChunkName: "pkg_____formComponent"*/ "./timePiker/timePiker")
);
const CheckboxInput = loadable(
  () => import(/*webpackChunkName: "pkg_____formComponent"*/ "./checkBox")
);
const ToggleInput = loadable(
  () => import(/*webpackChunkName: "pkg_____formComponent"*/ "./toggleButton")
);
const NameInput = loadable(
  () => import(/*webpackChunkName: "pkg_____formComponent"*/ "./nameInput")
);
const NumericInput = loadable(
  () => import(/*webpackChunkName: "pkg_____formComponent"*/ "./numericInput")
);
const TexInput = loadable(
  () => import(/*webpackChunkName: "pkg_____formComponent"*/ "./textInput")
);
const EmailInput = loadable(
  () => import(/*webpackChunkName: "pkg_____formComponent"*/ "./emailInput")
);
const PhoneNumberInput = loadable(
  () => import(/*webpackChunkName: "pkg_____formComponent"*/ "./phoneNumberInput")
);
const PasswordInput = loadable(
  () => import(/*webpackChunkName: "pkg_____formComponent"*/ "./passwordInput")
);
const DropdownSelect = loadable(
  () => import(/*webpackChunkName: "pkg_____formComponent"*/ "./dropdownSelect")
);
const RatingInput = loadable(
  () => import(/*webpackChunkName: "pkg_____formComponent"*/ "./ratingInput")
);
const MediaUpload = loadable(
  () => import(/*webpackChunkName: "pkg_____formComponent"*/ "./mediaUpload")
);
const HiddenInput = loadable(
  () => import(/*webpackChunkName: "pkg_____formComponent"*/ "./hiddenInput")
);
const BirthDayInput = loadable(
  () => import(/*webpackChunkName: "pkg_____formComponent"*/ "./birthDay")
);

export {
  EmailInput,
  NameInput,
  PasswordInput,
  PhoneNumberInput,
  TexInput,
  DropdownSelect,
  MediaUpload,
  RichTextComponent,
  DateTimePickerInput,
  RatingInput,
  DatePiker,
  TimePickerInput,
  CheckboxInput,
  NumericInput,
  ToggleInput,
  HiddenInput,
  CustumLocationSearch,
  CustumDefaultDentistSearch,
  CustumDefaultReviewDentistSearch,
  BirthDayInput
};
