import { AuthReducer, LOGIN_STATUS, } from "@core-interface";
import {
    SET_TOKEN,
    SET_USER,
    NEW_USER,
    SET_LOGING_STATUS,
    SET_EMERGENCY_USER,
    SET_SUCCESS_STATUS,
    GET_SUCCESS_STATUS,
    SET_DENTIST_COUNT,
    SET_VALIDATE_EMAIL,
    GET_VALIDATE_EMAIL,
    SET_EMAIL_VALIDATION_LINK,
    GET_EMAIL_VALIDATION_LINK,
    RESET_EMAIL_VERIFY_DETAILS,
    SUCCESS_STATUS_RESET
} from './auth.action';
import { setSession } from '@core-const';

const initState: AuthReducer = {
    session: null,
    loading: false,
    validateEmailLoading: false,
    loginStatus: LOGIN_STATUS.NONE,
    emergencyResponse: [],
    successStatus: false,
    dentistCount: 0,
    emailValidationDetails: {},
    emailNotVerified: false,
    notVerifiedEmail: undefined,
    linkgetLoading: false,
    verficationLinkGetStatus: undefined,
    userDetailsNotFetched: false
};

const reducer = (state = initState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_TOKEN:
            if (!payload) {
                return {
                    ...state,
                    userDetailsNotFetched: true
                };
            } else if (payload?.emailVerified) {
                setSession(payload);
                return {
                    ...state,
                    session: payload,
                    userDetailsNotFetched: false,
                    emailNotVerified: false,
                };
            } else {
                return {
                    ...state,
                    notVerifiedEmail: payload.email,
                    emailNotVerified: true
                };
            }
        case SET_USER:
            return {
                ...state,
            };
        case NEW_USER:
            return {
                ...state,
                loading: true,
            };
        case SET_LOGING_STATUS:
            return {
                ...state,
                loginStatus: payload
            };
        case GET_SUCCESS_STATUS:
            return {
                ...state,
                loading: false,
                successStatus: payload
            };
        case SET_SUCCESS_STATUS:
            return {
                ...state,
                loading: false,
                successStatus: payload
            };
        case SET_DENTIST_COUNT:
            return {
                ...state,
                dentistCount: payload
            };
        case SET_EMERGENCY_USER:
            return { ...state, emergencyResponse: [...state.emergencyResponse, payload], successStatus: payload.Success };
        case GET_VALIDATE_EMAIL:
            return {
                ...state,
                validateEmailLoading: true
            };
        case SET_VALIDATE_EMAIL:
            return {
                ...state,
                validateEmailLoading: false,
                emailValidationDetails: payload
            };
        case GET_EMAIL_VALIDATION_LINK:
            return {
                ...state,
                linkgetLoading: true,
            };
        case SET_EMAIL_VALIDATION_LINK:
            return {
                ...state,
                linkgetLoading: false,
                verficationLinkGetStatus: payload
            };
        case RESET_EMAIL_VERIFY_DETAILS:
            return {
                ...state,
                emailNotVerified: false,
                notVerifiedEmail: undefined,
                linkgetLoading: false,
                verficationLinkGetStatus: undefined
            };
        case SUCCESS_STATUS_RESET:
            return {
                ...state,
                successStatus: payload,
                emailValidationDetails: {}
            };
        default:
            return { ...state }
    }
}

export default reducer;