import { MyProfileNavigationType } from '@core-interface'

export const myProfileNavigation = (type = 2, id): Array<MyProfileNavigationType> => {
  const logoutUrl = process.env.REACT_APP_SIGN_OUT_URL;

  if (type === 1) {
    return [
      // {
      //   text: 'My profile',
      //   id: 'myProfile',
      //   url: 'profile',
      // },
      {
        text: 'Dentist Profile',
        id: 'dentist_profile',
        url: `dentistProfile/${id}`,
      },
      {
        text: 'My Chats',
        id: 'my_chats',
        url: `${process.env.REACT_APP_CHAT_URL}`,
      },
      {
        text: 'Emergency Requests',
        id: 'emergency_requests',
        url: 'emergencyRequests',
      },
      {
        text: 'Treatment Requests',
        id: 'treatment_requests',
        url: 'treatmentRequests',
      },
      {
        text: 'Appointments',
        id: 'appointments',
        url: 'appointments',
      },
      // {
      //   text: 'Channel History',
      //   id: 'channelHistory',
      //   url: 'channelHistory',
      // },
      {
        text: 'Reviews',
        id: 'reviews',
        url: 'reviews',
      },
      {
        text: 'Logout',
        id: 'logout',
        url: logoutUrl,
      }
    ];
  } else {
    return [
      {
        text: 'My profile',
        id: 'my_profile',
        url: 'profile',
      },
      {
        text: 'My Chats',
        id: 'my_chats',
        url: `${process.env.REACT_APP_CHAT_URL}`,
      },
      {
        text: 'Emergency Requests',
        id: 'emergency_requests',
        url: 'emergencyPatientRequests',
      },
      {
        text: 'Treatment Requests',
        id: 'treatment_requests',
        url: 'treatmentPatientRequests',
      },
      {
        text: 'Appointments',
        id: 'appointments',
        url: 'appointments',
      },
      {
        text: 'Reviews',
        id: 'reviews',
        url: 'reviews',
      },
      {
        text: 'Logout',
        id: 'logout',
        url: logoutUrl,
      }
    ];
  }

};
