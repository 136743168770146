import styled, { css } from "styled-components";
import { Page } from "../../core/styled/components/containers";
import { SubTitle } from "../../core/styled/components/titles/index";
import icon from "../../core/styled/components/containers/icon";
import { device } from "@core-const";
import { FlexRow } from 'app-styled/flex';

const ResultsWrapper = styled(Page)`
  margin-top: 71px;
`;

const FilterWrapper = styled.div`
  grid-column: span 3 / auto;
`;

export const ResultContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
export const FilterOptionWrapper = styled.div`
  width: 300px;
  ${({ theme }) => css`
    margin-right: ${theme["thm-space-lg"]};
    margin-bottom: ${theme["thm-space-lg"]};
  `}

  @media ${device.tablet} {
    width: 0px;
    margin-right: 0;
  }
`;
export const FilterResultWrapper = styled.div`
  width: calc(100% - 300px);
  ${({ theme }) => css`
    padding-bottom: ${theme["thm-space-xl"]};
  `}
  @media ${device.tablet} {
    width: 100%;
  }
`;

const FilterBody = styled.div`
  position: inherit;
  width: fit-content;
  ${({ theme }) => css`
    margin: ${theme["margin-labelSmall"]};
    font-size: ${theme["fontSize-message"]};
    font-family: ${theme["fontFamily.bold"]};
  `}
`;

const FilterTiltle = styled(SubTitle)`
  font-size: 1rem;
  margin-bottom: 0;
`;

const FilterSubTiltle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: small;
  ${({ theme, bgNone }) => css`
    background: ${bgNone ? "none" : theme["color-positiveLightXX"]};
    padding: ${theme["padding-labelSmall"]};
    margin: ${theme["margin-labelSmall"]};
    font-family: ${theme["fontFamily.bold"]};
  `}
`;

const FilterIcon = styled(icon)`
  ${({ theme, border }) => css`
    background: ${theme["color-light"]};
    ${border &&
    css`
      margin-top: ${theme["margin-elementSmall"]};
      border-style: solid;
      background: ${theme["color-light"]};
      border-color: ${theme["color-border"]};
      border-radius: ${theme["radius-medium"]};
    `}
  `}
`;

const FilterCardWrapper = styled(FlexRow)`
  border-style: solid;
  justify-content: center;
  ${({ theme, borderMin }) => css`
    padding: ${theme["padding-dropMenu"]};
    margin: ${theme["margin-dropMenu"]};
    background: ${theme["color-light"]};
    border-color: ${theme["color-border"]};
    border-radius: ${theme["radius-medium"]};
  `}
`;

export const NoResultCard = styled.div`
  ${({ theme }) => css`
    padding: ${theme["padding-large"]};
  `}
`;

export {
  ResultsWrapper,
  FilterWrapper,
  FilterBody,
  FilterTiltle,
  FilterSubTiltle,
  FilterIcon,
  FilterCardWrapper,
};
