import { ValidatePracticeNameProps } from "@core-interface";

export const GET_PROFILE_DATA = 'GET_PROFILE_DATA';
export const getProfileData = payload => ({ type: GET_PROFILE_DATA, payload });

export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const setProfileData = payload => ({ type: SET_PROFILE_DATA, payload });

export const UPDATE_PROFILE_DATA = 'UPDATE_PROFILE_DATA';
export const updateProfileData = payload => ({ type: UPDATE_PROFILE_DATA, payload });

export const SET_UPDATED_PROFILE_DATA = 'SET_UPDATED_PROFILE_DATA';
export const setUpdatedProfileData = payload => ({ type: SET_UPDATED_PROFILE_DATA, payload });

export const GET_REVIEW_PROFILE_DATA = 'GET_REVIEW_PROFILE_DATA';
export const getReviewProfileData = payload => ({ type: GET_REVIEW_PROFILE_DATA, payload });

export const SET_REVIEW_PROFILE_DATA = 'SET_REVIEW_PROFILE_DATA';
export const setReviewProfileData = payload => ({ type: SET_REVIEW_PROFILE_DATA, payload });

export const GET_ORGANIZATION_ID = "GET_ORGANIZATION_ID";
export const getOrganizationId = (payload) => ({ type: GET_ORGANIZATION_ID, payload });

export const SET_ORGANIZATION_ID = "SET_ORGANIZATION_ID";
export const setOrganizationId = (payload) => ({ type: SET_ORGANIZATION_ID, payload });

export const GET_PRACTICENAME_AVAILABILITY = "GET_PRACTICENAME_AVAILABILITY";
export const getPracticeNameAvailability = (payload: ValidatePracticeNameProps) => ({ type: GET_PRACTICENAME_AVAILABILITY, payload });

export const SET_PRACTICENAME_AVAILABILITY = "SET_PRACTICENAME_AVAILABILITY";
export const setPracticeNameAvailability = (payload: boolean) => ({ type: SET_PRACTICENAME_AVAILABILITY, payload });

