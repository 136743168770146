import styled, { css } from 'styled-components';
import { device } from "@core-const";
import { FlexRow, FlexCol, FlexCenter } from 'app-styled/flex';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Image } from 'app-styled/components';
import { LinkSmall } from 'app-styled/link';

export const FooterWrapper = styled(FlexCol)`
    margin-left: -2.5rem;
    margin-right: -2.5rem;
    ${({ theme }) => css`
        background-color:${theme['thm-form']};
        padding: ${theme['thm-space-xl']};
        @media ${device.tablet} {
            padding-bottom: 0;
            margin-left: -1rem;
            margin-right: -1rem;
        }
    `}

    @media ${device.mobileL} { 
        margin-left: -0.75rem;
        margin-right:-0.75rem;
      }
`

export const ContactContainer = styled(FlexRow)`
    ${({ theme }) => css`
        background-color:${theme['thm-light']};
        padding: ${theme['thm-space-xl']};
        margin: ${theme['thm-space-xl']};
        border-radius:${theme['thm-radius']};

        @media ${device.laptop} {
            margin:0;
            padding:${theme['thm-space-xl']};
        }

        @media ${device.tablet} {
            padding: ${theme['thm-space-lg']} ${theme['thm-space-lg']};
            flex-direction: column;
            align-items: self-start;
        }
    `}
`

export const ContactItem = styled(FlexRow)`
    flex:1;
    justify-content: center;

    ${({ theme }) => css`
        @media ${device.tablet} {
            padding: ${theme['thm-space-lg']} ${theme['thm-space-xl']};
        }
        @media ${device.mobileL} {
            padding: ${theme['thm-space-lg']} ${theme['thm-space-md']};
        }
    `}

`

export const ContactItemIcon = styled(FontAwesomeIcon)`
    font-size: 2rem;
    border: 1px solid;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    ${({ theme }) => css`
        border: 1px solid ${theme['thm-secondary']};
        padding: ${theme['thm-space-lg']};

        @media ${device.laptop} {
            width: 15px;
            height: 15px;
            font-size: 1.5rem;
        }
    `}
`

export const ContactItemInfoContainer = styled(FlexCol)`
    align-items: self-start;
    justify-content: flex-start;
    ${({ theme }) => css`
        margin-left: ${theme['thm-space-md']};
    `}
`

export const ContactLabel = styled(FlexCenter)`
    font-weight: 500;
    @media ${device.tablet} {
        font-size: 12px;
    }

`

export const ContactValue = styled(FlexCenter)`
    font-size: 15px;
    ${({ theme }) => css`
    color: ${theme['thm-b-text']};

    @media ${device.laptopL} {
        font-size: 16px;
    }

    @media ${device.tablet} {
        font-size: 14px;
    }
`}
`

export const CopyrightsContainer = styled(FlexRow)`
    ${({ theme }) => css`
        margin: ${theme['thm-space-md']} ${theme['thm-space-xl']};
        margin-bottom: 0;

        @media ${device.laptop} {
            margin: ${theme['thm-space-xl']} 0;
        };
        @media ${device.tablet} {
            font-size:12px;
        };
        @media ${device.mobileL} {
            font-size:12px;
            flex-direction: column;
            align-items: center;
        }
    `}
 
`

export const LeftContainer = styled(FlexRow)`
    align-items: center;

`;

export const Logo = styled(Image)`
    height:40px;
    width:116px;

    @media ${device.tablet} {
        height:25px;
        width: auto;
    }
    ${({ theme }) => css`
    margin-right: ${theme['thm-space-md']};
    `}
`;

export const RightContainer = styled(FlexRow)`
    margin-left:auto;
    align-items: center;
    flex-wrap: wrap;
    ${({ theme }) => css`
    @media ${device.mobileL} {
        padding-top: ${theme['thm-space-md']};
        margin-left: unset;
    }
    `};
`;

export const Separator = styled.span`
    ${({ theme }) => css`
        margin: 0 ${theme['margin-labelSmall']};
    `}
`;

export const FooterLink = styled(LinkSmall)`
    ${({ theme }) => css`
        color: ${theme['color-black']};
        :hover{
            color: ${theme['color-black']};
        }
    `};
    font-size: inherit;
    font-weight: unset;

`;