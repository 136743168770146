export interface AuthReducer {
    session?: Session;
    loading: Boolean;
    validateEmailLoading?: boolean,
    loginStatus?: LOGIN_STATUS;
    emergencyResponse: any;
    successStatus: boolean;
    dentistCount: number;
    emailValidationDetails?: any;
    emailNotVerified?: boolean;
    notVerifiedEmail: string | undefined;
    linkgetLoading: boolean;
    verficationLinkGetStatus: boolean | undefined;
    userDetailsNotFetched: boolean
}

export interface Session {
    accessToken?: string,
    id?: string,
    username?: string,
    role?: number,
    spDentistId?: string,
    userId?: string,
    sub: string,
    nickName: string,
    name: string,
    picture: string,
    updated_at: string,
    email: string,
    email_verified: boolean,
    latitude?: number,
    longitude?: number,
    loginType?: number,
    clientId?: number
};

export enum LOGIN_STATUS {
    NONE,
    INPROGRESS,
    LOGEDIN
};