import styled, { css } from 'styled-components';
import { device } from "@core-const";

export const Title = styled.div`
  ${({ theme }) => css`
    font-size: 1rem;
    font-weight: 100;
    color: ${theme['color-dark']};
    margin-bottom: ${theme['margin-small']};
  `}

  ${({ theme, thin }) =>
    thin &&
    css`
      font-family: ${theme['fontFamily-thin']};
    `}

    ${({ theme, bold }) =>
    bold &&
    css`
      font-family: ${theme['font-bold']};
    `}

    ${({ theme, regular }) =>
    regular &&
    css`
      font-family: ${theme['fontFamily-regular']};
    `}
`;

export const MainTitle = styled(Title)`
  ${({ theme }) => css`
    font-size: 3rem;
    line-height: 3rem;
    margin-bottom: ${theme['space-md']};
  `}
`;

export const SubTitle = styled(Title)`
  ${({ theme }) => css`
    font-size: 2rem;
    margin-bottom: ${theme['space-md']};
  `}
`;

export const SmallTitle = styled(Title)`
  ${({ theme }) => css`
    font-size: 0.75rem;
    margin: ${theme['margin-labelSmall']};
  `}
`;

export const LabelTiltle = styled(Title)`
  ${({ theme }) => css`
    font-size: ${theme['fontSize-label']};
    color: ${theme['color-dark']};
    margin: ${theme['margin-labelSmall']};
  `}
`;

export const WindowTitle = styled.div`
  ${({ theme }) => css`
      color: ${theme['body-bg']};
      font-size: 1.2rem;
      font-family: ${theme['font-bold']};
      text-shadow: 2px 2px #000000;
    `}
`;


export const ItemTitle = styled.h3`
  letter-spacing: 0.05rem;
  min-width: 100px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: baseline;
  width: fit-content;
  ${({ theme }) => css`
    font-size: 1.5rem;
    font-family: ${theme["fontFamily-bold"]};
  `}

  ${({ small }) => small && css`
      font-size: 1rem;
  `}

  @media ${device.tablet} {
    font-size: 1rem;
  }
`;

export const NoData = styled.h3`
  font-style: italic;
  display: flex;
  justify-content: center;
  align-items: center;
${({ theme }) =>
    css`
    font-size: ${theme['fontSize-elementSmall']};
    color: ${theme['color-dark']};
    margin: ${theme['thm-space-xl']};
    font-family: ${theme['fontFamily-regular']};
  `}
`;



