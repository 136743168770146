import { combineEpics } from 'redux-observable';
import ResultEpic from '../pages/list/results.epic';
import AuthEpic from '../modules/auth/auth.epic';
import CoreEpic from '../core/core.epic';
import ProfileEpic from '../pages/profile/profile.epic';
import RegisterEpic from '../pages/register/register.epic';
import TreatmentsEpic from '../pages/profile/treatments/treatments.epic';
import MyProfileEpic from '../pages/myProfile/myProfile.epic';
import PorfileAppointmentsEpic from '../pages/myProfile/appointments/appointments.epic';
import PorfileAppointmentViewEpic from '../pages/myProfile/appoinmentCommon/appointmentCommon.epic';
import ReviewsAppointmentsEpic from '../pages/myProfile/reviews/reviews.epic';
import TreatmentReviewEpic from '../pages/myProfile/patientReviewForm/patientReview.epic';
import PatientProfileEpic from '../pages/myProfile/patientProfile/patientProfile.epic';
import TreatmentReviewDataEpic from '../pages/myProfile/reviewCommon/reviewCommon.epic';
import ServicesEpic from '../pages/services/services.epic';

const rootEpic = combineEpics(
    ResultEpic, 
    AuthEpic, 
    CoreEpic, 
    ProfileEpic, 
    RegisterEpic,
    TreatmentsEpic,
    MyProfileEpic,
    PorfileAppointmentsEpic,
    ReviewsAppointmentsEpic,
    TreatmentReviewEpic,
    TreatmentReviewDataEpic,
    PatientProfileEpic,
    ServicesEpic,
    PorfileAppointmentViewEpic
);

export default rootEpic;
