import React, { memo } from "react";
import { Message } from "@localization";
import loadable from "@loadable/component";
import { Session } from "@core-interface";
import { getSession, loginUrl } from "@core-const";
import * as SC from "./register.styled";

const DentistRegisterForm = loadable(
  () => import(/*webpackChunkName: "pkg_____register"*/ "./dentistRegisterForm")
);
const Footer = loadable(
  () => import(/*webpackChunkName: "pkg_____register"*/ "../../modules/footer")
);

const RegisterPage = () => {
  const session: Session = getSession();
  const onLoginClick = () => {
    // const newPageUrl = `${process.env.REACT_APP_AUTH_ZERO_URL}`;
    // window.open(newPageUrl, '_self');
    loginUrl();
  };

  return (
    <>
      <SC.RegisterWrapper>
        <SC.Welcome>
          <Message
            messageKey={"welcome_to_the_smylor"}
            defaultMessage={"Welcome to the smylor"}
          />
        </SC.Welcome>
        <SC.RegisterContainer>
          <SC.RegisterPageColumnBody>
            <SC.RegisterFormTitle bold>
              <Message
                messageKey={"register_as_a_dentist"}
                defaultMessage={"Dentist registration"}
              />
            </SC.RegisterFormTitle>
            <SC.RegisterFormBody>
              {!session && (
                <>
                  <SC.DentistLoginContainer>
                    <SC.RegisterTitle small>
                      <Message
                        messageKey={"already_registered"}
                        defaultMessage={"Already Registered?"}
                      />
                    </SC.RegisterTitle>
                    <SC.LoginButton light onClick={onLoginClick}>
                      <Message
                        messageKey={"login_here"}
                        defaultMessage={"Login here"}
                      />
                    </SC.LoginButton>
                  </SC.DentistLoginContainer>
                  <SC.RegitorFormDescription>
                    <Message
                      messageKey={"register_as_a_dentist_description"}
                      defaultMessage={
                        "Join 100s of Dentists going online with Smylor, Free online chat app included"
                      }
                    />
                  </SC.RegitorFormDescription>
                </>
              )}
              <DentistRegisterForm />
            </SC.RegisterFormBody>
          </SC.RegisterPageColumnBody>
          <SC.BackImage image={"regiter"}></SC.BackImage>
        </SC.RegisterContainer>
      </SC.RegisterWrapper>
      <SC.FooterContainer>
        <Footer />
      </SC.FooterContainer>
    </>
  );
};

export default memo(RegisterPage);
