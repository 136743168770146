import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { setActionBussy } from './actions';
import { switchMap, tap } from 'rxjs/operators';
import { Session } from "@core-interface";
import { logoutUser, getSession } from '@core-const';

const httpPostPayment = ({ call, data }) => {
  return httpCall('POST', call, data);
};

const httpGetPayment = ({ call }) => {
  return httpCall('GET', call);
};

const httpDeletePayment = ({ call }) => {
  return httpCall('DELETE', call);
};

const httpPutPayment = ({ call, data }) => {
  return httpCall('PUT', call, data);
};

const httpCall = (type, call, data = null) => {
  setActionBussy(true);

  let request_url = process.env.REACT_APP_PAYMENT_URL;

  let headers: any = {
    'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Origin': 'http://localhost:1096/api/',
    'Content-type': 'application/json',
    'Access-Control-Max-Age': 7200,
  };

  let jsonData = null;
  if (data) {
    jsonData = JSON.stringify(data);
  }

  const session: Session = getSession()
  request_url = `${request_url}/${call}`;
  if (session) {
    headers = {
      ...headers,
      'Authorization': `Bearer ${session.accessToken}`,
    };
  }

  return ajax({
    url: request_url,
    crossDomain: true,
    method: type,
    responseType: 'json',
    timeout: 0,
    headers,
    body: data ? jsonData : null,
    createXHR: function () {
      return new XMLHttpRequest();
    },
  }).pipe(
    tap(result => setActionBussy(false)),
    switchMap(data => of(data.response)),
  );
};

const apiStatus = {
  success: 200,
};
export { httpPostPayment, httpGetPayment, httpDeletePayment, httpPutPayment, apiStatus };
