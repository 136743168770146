import React from 'react'
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
    border: 2px solid;
    border-radius: 0.3rem;
    ${({ theme }) => css`
        border-color: ${theme['color-border']};
        background: ${theme['color-light']};
        padding: ${theme['space-md']} ${theme['space-lg']};
        margin: ${theme['space-md']} 0;
    `};
    
    ${({ theme, accepted }) => accepted && css`
        background-color: ${theme['thm-border']};
        border-color: ${theme['color-positive']}
    `};

    ${({ theme, backgroundColor }) => backgroundColor && css`
        background-color: ${theme['color-backgroundLightx']};
    `};
    ${({ theme, flex }) => flex && css`
        display: flex !important;
        flex-direction: row;
        gap: ${theme['space-md']};
        justify-content: space-between;
        align-items: center;
    `};

    :hover{
        ${({ theme }) => css`
            border-color: ${theme['color-positive']};
        `}
    }
`
const ListItemContainer = (props) => {
    return <Wrapper flex={props.flex} backgroundColor={props.backgroundColor} accepted={props.accepted}>{props.children}</Wrapper>
}

export default ListItemContainer;