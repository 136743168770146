import { rootState } from '@core-interface'

export const getProfileDataFromReducer = (state:rootState) => state?.profileReducer?.profileData?.Data;

export const getTreatmentServicesFromReducer = (state:rootState) => state?.profileReducer?.treatmentServices;

export const getProfileTreatmentServicesFromReducer = (state:rootState) => state?.profileReducer?.selectedTreatmentServices;

export const getUpdatetreatmentTypeLoadingStatus = (state:rootState) => state?.profileReducer?.addTreatmentTypeLoading;

export const getProfileReviewFromReducer = (state:rootState) => state?.profileReducer?.reviewProfileData;

export const getDentistOranizationIDFromReducer = (state:rootState) => state?.profileReducer?.organizationID;

export const getpracticeNameExistLoadingStatus = (state:rootState) => state?.profileReducer?.practiceNameExistLoading;
export const getpracticeNameExistFromReducer = (state:rootState) => state?.profileReducer?.practiceNameExist;