import { ofType, combineEpics } from "redux-observable";
import { switchMap, map } from "rxjs/operators";
import { httpGet } from "@core-http";
import { GET_REVIEWS_DATA, setProfileReviewsData } from "./reviews.actions";

export const epicGetProfielReviewsData = (action$, state$) => {
  return action$.pipe(
    ofType(GET_REVIEWS_DATA),
    switchMap(({ payload: { id, take, page } }) =>
      httpGet({
        call: `TreatmentReview/GetUserReviewSummery?${id}&take=${take}&page=${page}`,
      }).pipe(
        map(({ Data, Total }: any) =>
          setProfileReviewsData({ Data, Total, page })
        )
      )
    )
  );
};

const ProfielReviewsEpic = combineEpics(epicGetProfielReviewsData);

export default ProfielReviewsEpic;
