import { ReviewsReducer } from "@core-interface";
import { GET_REVIEWS_DATA, SET_REVIEWS_DATA } from "./reviews.actions";

const initState: ReviewsReducer = {
  reviewsData: [],
  total: 0,
  loading: false,
};

const reducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_REVIEWS_DATA:
      return {
        ...state,
        loading: true,
      };
    case SET_REVIEWS_DATA:
      return {
        ...state,
        reviewsData:
          payload.page === 1
            ? payload.Data
            : [...state.reviewsData, ...payload.Data],
        total: payload.Total,
        loading: false,
      };
    default:
      break;
  }
  return state;
};

export default reducer;
