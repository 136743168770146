import { useRoutes, Navigate, useSearchParams, useLocation } from 'react-router-dom';
import HomeComponent from '../../pages/home'
import ListPage from '../../pages/list';
import ProfilePage from '../../pages/profile';
import RegisterPage from '../../pages/register';
import SigninPage from '../../pages/signin';
import MyProfilePage from '../../pages/myProfile';
import MyProfileRoutes from '../../pages/myProfile/myProfile.router';
import ServicesPage from '../../pages/services';
import PrizingPage from '../../pages/pricing';
import MainHeader from './mainHeader';
import { Session } from '@core-interface';
import { getSession, loginUrl } from '@core-const';
import BookingNavigator from 'src/pages/booking';
import OpeningHoursNavigator from 'src/pages/openingHours';
import PrivacyPage from '../../pages/privacy';
import TermsPage from '../../pages/terms';

const MainRoutes = () => {
    const session: Session = getSession();
    let { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const userType = searchParams.get("userType");

    let ProfileElement = <MainHeader showPreHeader={false}><MyProfilePage /></MainHeader>;
    if (!session) {
        if (userType) {
            localStorage.setItem('redirectUrl', pathname);
        };
        if (userType === '1')
            ProfileElement = <Navigate to={'/register'} replace />
        else if (userType === '2') {
            loginUrl();
        } 
    };

    const routes = useRoutes([
        {
            path: '/',
            element: <MainHeader showPreHeader={true}><HomeComponent /></MainHeader>,
        },
        {
            path: '/list',
            element: <MainHeader showPreHeader={false}><ListPage /></MainHeader>,
        },
        {
            path: '/profile/:id',
            element: <MainHeader showPreHeader={false}><ProfilePage /></MainHeader>,
        },
        {
            path: '/myprofile',
            element: ProfileElement,
            children: MyProfileRoutes(),
        },
        {
            path: '/services/:id',
            element: <MainHeader showPreHeader={false}><ServicesPage /></MainHeader>,
        },
        {
            path: '/register',
            element: <MainHeader showPreHeader={false}><RegisterPage /></MainHeader>,
        },
        {
            path: '/signup',
            element: <MainHeader showPreHeader={false}><SigninPage /></MainHeader>,
        },
        {
            path: '/pricing',
            element: <MainHeader showPreHeader={true}><PrizingPage /></MainHeader>,
        },
        {
            path: '/booking/:id',
            element: <MainHeader showPreHeader={false}><BookingNavigator /></MainHeader>,
        },
        {
            path: '/openingHours/:id',
            element: <MainHeader showPreHeader={false}><OpeningHoursNavigator /></MainHeader>,
        },
        {
            path: '/privacy',
            element: <MainHeader showPreHeader={true}><PrivacyPage /></MainHeader>,
        },
        {
            path: '/terms',
            element: <MainHeader showPreHeader={true}><TermsPage /></MainHeader>,
        },
    ])
    return routes;
}

export default MainRoutes;