import styled, { css } from "styled-components";
import { Title, ItemTitle } from "app-styled-components";
import { device } from "@core-const";
import { TitleH2, TitleH3, Label } from "app-styled/title";
import { FlexRow, FlexCol } from "app-styled/flex";
import { LinkButtonSmall, LinkButtonXSmall } from "app-styled/link";

export const RegisterWrapper = styled(FlexCol)`
  margin-top: 71px;
  align-items: center;
  ${({ theme }) => css`
    background-color: ${theme["thm-form"]};
  `}
`;

export const RegisterContainer = styled(FlexRow)`
  overflow: hidden;
  ${({ theme }) => css`
    margin: ${theme["thm-space-xl"]};
    margin-top: 0px;
    background-color: ${theme["thm-light"]};
    border-radius: 10px;
    box-shadow: ${theme["smylor-pink"]} 0px 1px 2px 0px;
    @media ${device.tabletL} {
      flex-direction: column-reverse;
    }
    @media ${device.mobileL} {
      margin: ${theme["thm-space-lg"]};
    }
  `}
`;

export const SigninContainer = styled(FlexRow)`
  overflow: hidden;
  ${({ theme }) => css`
    margin: ${theme["thm-space-xl"]};
    margin-top: 0px;
    background-color: ${theme["thm-light"]};
    border-radius: 10px;
    box-shadow: ${theme["smylor-pink"]} 0px 1px 2px 0px;
    @media ${device.tabletL} {
      flex-direction: column-reverse;
    }
    @media ${device.mobileL} {
      width: calc(100% - 32px);
      margin: ${theme["thm-space-lg"]};
    }
  `}
`;

export const BackImage = styled.div`
  width: 600px;
  min-height: 500px;
  background-size: cover;

  ${({ theme, image }) => css`
    background-image: url("/assets/img/${image}.png");
  `}

  @media ${device.laptopL} {
    width: 500px;
    background-position-x: -200px;
  }

  @media ${device.laptop} {
    width: 400px;
    background-position: -300px center;
  }

  @media ${device.tabletL} {
    display: none;
  }
`;

export const BackImageSignin = styled.div`
  width: 600px;
  height: auto;
  background-size: cover;

  ${({ theme, image }) => css`
    background-image: url("/assets/img/${image}.png");
  `}

  @media ${device.laptopL} {
    width: 500px;
  }

  @media ${device.laptop} {
    width: 400px;
    background-position-x: -50px;
  }

  @media ${device.tabletL} {
    display: none;
  }
`;

export const Welcome = styled(TitleH2)`
  ${({ theme }) => css`
    margin: ${theme["thm-space-xl"]};
    text-align: center;
    @media ${device.mobileL} {
      margin: ${theme["thm-space-lg"]};
  }
  `}
`;

export const RegisterPageColumnBody = styled(FlexCol)`
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 600px;
  @media ${device.laptopL} {
    width: 500px;
  }

  @media ${device.tablet} {
    width: 400px;
  }

  @media ${device.mobileL} {
    width: 100%;
  }

  @media ${device.mobileS} {
    max-width: 300px;
  }
`;

export const RegisterFormTitle = styled(TitleH3)`
  ${({ theme }) => css`
    color: ${theme["body-bg"]};
    background-color: ${theme["thm-base"]};
    padding: ${theme["thm-space-lg"]} ${theme["thm-space-xl"]};

    @media ${device.tablet} {
      font-size: 35px;
    }
  `}
`;

export const ButtonContainer = styled(FlexRow)`
  margin-left: auto;
  justify-content: flex-end;
  ${({ theme }) => css`
    margin: ${theme["thm-space-xl"]} 0;
    margin-right: 1.5rem;

    @media ${device.tablet} {
      margin-right: 0;
      justify-content: flex-end;
    }

    @media ${device.mobileL} {
      margin-top: ${theme["thm-space-lg"]};
    }
  `}
  button{
    z-index: auto;
  }
`;

export const RegisterFormBody = styled.div`
  ${({ theme }) => css`
    padding: ${theme["padding-container"]};
    @media ${device.tablet} {
      padding: 0;
    }
  `}
`;

export const RegisterFormInputContainer = styled.div`
  ${({ theme }) => css`
      padding: 0 ${theme["padding-container"]};
      .k-form-error {
        padding: 0 !important;
      }
    @media ${device.mobileL} {
        padding: 0 ${theme["thm-space-md"]};
    }
  `}
`;

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    padding-right: ${theme["padding-container"]};
    @media ${device.mobileS} {
        padding: 0 ${theme["thm-space-md"]};
    }
  `}
`;

export const LoadingWrapper = styled.div`
  ${({ theme }) => css`
      position: absolute;
      top: 26px;
      right: -10px;
      /* padding-right: ${theme["padding-container"]}; */
    @media ${device.mobileL} {
        /* padding: 0 ${theme["thm-space-md"]}; */
    }
  `}
`;

export const LoggedMessage = styled(Title)`
  display: flex;
  justify-content: center;
  ${({ theme }) => css`
    color: ${theme["color-backgroundDark"]};
  `}
`;

export const RegisterTitle = styled(ItemTitle)`
  ${({ theme }) => css`
  @media ${device.mobileS} {
       font-size: ${theme["thm-font-sm"]};
    }
  `}
`;

export const DentistLoginContainer = styled(FlexRow)`
  gap: 8px;
  align-items: baseline;
  ${({ theme }) => css`
    padding: 0px ${theme["thm-space-xl"]} ${theme["thm-space-md"]}
      ${theme["thm-space-xl"]};
    @media ${device.mobileL} {
      padding: ${theme["thm-space-md"]};
    }
    @media ${device.mobileS} {
      gap: 4px;
    }
  `}
`;

export const LoginHere = styled.a`
  cursor: pointer;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  ${({ theme }) => css`
    font-size: ${theme["thm-font-lg"]};
  `}
`;

export const RegitorFormDescription = styled.div`
  ${({ theme }) => css`
    font-style: italic;
    line-height: 18px;
    font-size: ${theme["thm-font-md"]};
    font-family: ${theme["fontFamily-regular"]};
    padding: 0px ${theme["thm-space-xl"]} ${theme["thm-space-lg"]} ${theme["thm-space-xl"]};
    margin-top: ${theme['thm-space-lg']};
    @media ${device.mobileL} {
      padding: ${theme["thm-space-md"]};
      margin-top: ${theme['thm-space-md']};
    }
  `};
`;

export const DentistAvailabilityDescription = styled.div`
  ${({ theme }) => css`
    font-style: italic;
    line-height: 18px;
    font-size: ${theme["thm-font-md"]};
    font-family: ${theme["fontFamily-regular"]};
    color: ${theme["smylor-pink"]};
    padding: 0px ${theme["thm-space-xl"]} ${theme["thm-space-lg"]} ${theme["thm-space-xl"]};
    @media ${device.mobileL} {
      padding: ${theme["thm-space-md"]};
    }
  `};
`;

export const LoginButton = styled(LinkButtonXSmall)`
  ${({ theme }) => css`
    border: 1px solid;
    background: inherit;
    @media ${device.mobileS} {
    margin: 0px;
  }
  `}
`;

export const FooterContainer = styled.div`
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  /* width: calc(100% - 80px); */
  @media ${device.tablet} {

    margin: 0rem 1rem;
  }
  @media ${device.mobileL} {
    margin: 0rem .75rem;
  };
`;

export const FormItemWrapper = styled.div`
  ${({ theme }) => css`
    margin: ${theme["thm-space-lg"]} 0;
      @media ${device.tablet} {
        margin: 0;
        padding: 0 ${theme["thm-space-md"]} ${theme["thm-space-md"]} ${theme["thm-space-md"]};
      };
  `};

  .checkboxCustomStyle {
    display: flex;
    flex-direction: row;
    font-size: 0.9rem;
    text-align: left;
    line-height: 1.2;
    letter-spacing: .1px;
    ${({ theme }) => css`
      font-family: ${theme["font-regular"]};
      gap: ${theme["thm-space-md"]};
    `};
  }
  .k-checkbox {
    ${({ theme }) => css`
      margin: ${theme["thm-space-sm"]};
    `};
  }
`;

export const PromoTextContainer = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme["thm-space-xl"]};
    padding-bottom: ${theme["thm-space-md"]};
    @media ${device.mobileL} {
      margin: 0;
      padding: 0 ${theme["thm-space-md"]};
      padding-bottom: ${theme["thm-space-md"]};
    };
  `}
`;

export { LinkButtonSmall };
