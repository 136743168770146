import React, { useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLocalization } from "@progress/kendo-react-intl";
import loadable from "@loadable/component";
import DrawMetaTags from "app-styled/metatags";
import { ResultsWrapper } from "./results.styled";
import { getSearchData, setBookNowPopupData } from "./results.actions";
import { getResultList, getBookNowPopupStatus } from "./result.selectors";
import { setBreadcrumb } from "@core-action";
import { Breadcrumb } from "./results.constants";
import { getFilters } from "../../core/core.selectors";
import { setFilter } from "@core-action";
import { USER_ROLES, getSession } from "@core-const";
import { getLanguageFromReducer } from "../../core/styled/components/shared/mainSearch/mainSearch.selector";
import { getTreatmentServicesFromReducer } from "../profile/profile.selector";
import { getTreatmentServicesData } from "../profile/treatments/treatments.actions";
import * as SC from "./results.styled";
import { readParams, sortParams } from "./urlParamGenerators";

const BreadcrumbContainer = loadable(() =>
  import(/*webpackChunkName: "pkg_____list"*/ "../../modules/breadcrumb")
);
const FilterOptions = loadable(() =>
  import(/*webpackChunkName: "pkg_____list"*/ "./filterOptions")
);
const FilterResult = loadable(() =>
  import(/*webpackChunkName: "pkg_____list"*/ "./filterResults")
);
const RelatedSearch = loadable(() =>
  import(/*webpackChunkName: "pkg_____list"*/ "./relatedSearch")
);
const BookNowPopup = loadable(() =>
  import(/*webpackChunkName: "pkg_____list"*/ "./bookNowPopup")
);
const Footer = loadable(() =>
  import(/*webpackChunkName: "pkg_____list"*/ "../../modules/footer")
);

const ListPage = () => {
  const dispatch = useDispatch();
  const localization = useLocalization();
  const [searchParams] = useSearchParams();
  const [showFilters, seltShowFilters] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [broadcastOpen, setBroadcastOpen] = useState(searchParams.get('broadcast'));
  const [selectedLanguage, setSlectedLanguage] = useState("En_Us");
  const [autoSelectData, setAutoSelectData] = useState([]);
  const [paramData, setParamData] = useState({});
  const list = useSelector(getResultList);
  const filter = useSelector(getFilters);
  const { showBookNow } = useSelector(getBookNowPopupStatus);
  const lanaguage = useSelector(getLanguageFromReducer);
  const treatmentServices = useSelector(getTreatmentServicesFromReducer);
  const breadcrumbData = Breadcrumb();
  const navigate = useNavigate();

  const keywords = localization.toLanguageString("meta_listpage_keywords", "smylor, dentist, emergency, dental, patient");
  const metaDescription = localization.toLanguageString("meta_listpage_description",
    "Smylor is a leading dental marketplace for finding, booking and reviewing dental practice treatments. Includes the ability to request a treatment from 100s of dentists in a local area."
  );
  const metaImageUrl = `${process.env.REACT_APP_ROOT_URL}/assets/img/banner-1920x1280.jpg`;
  const metaTitle = localization.toLanguageString("meta_listpage_title", "Dental Treatments Marketplace | Smylor");
  const metaPageUrl = process.env.REACT_APP_ROOT_URL;

  const session = getSession();
  useEffect(() => { dispatch(getTreatmentServicesData()) }, []);

  useEffect(() => {
    setSlectedLanguage(lanaguage);
    dispatch(setBreadcrumb(breadcrumbData));
  }, [lanaguage]);

  useEffect(() => {
    setAutoSelectData(
      treatmentServices.map(
        ({ Title, Image, ServiceId, Description, TreatmentType, Id }) => ({ Title, Image, ServiceId, Description, TreatmentType, Id, })
      )
    );
  }, [JSON.stringify(treatmentServices)]);

  useEffect(() => {
    dispatch(setFilter(readParams(searchParams)));
    setLat(searchParams.get("lat"));
    setLng(searchParams.get("lng"));
  }, []);

  const userEmail = () => { if (session) return (session?.role === USER_ROLES.PATIENT) ? session?.email : null };

  useEffect(() => {
    const treatment = { type: filter.treatmentType, ratingMin: filter.review.treatmentMin, ratingMax: filter.review.treatmentMax };

    const param = {
      ServiceId: filter.treatmentType,
      Culture: selectedLanguage || filter.Culture,
      User: userEmail(),
      treatment,
      practiceRatingMin: filter.review.practiceMin,
      practiceRatingMax: filter.review.practiceMax,
      priceMin: filter.affordability.priceMin,
      priceMax: filter.affordability.priceMax,
      searchText: filter.searchText,
      maxDistanceInKm: filter.dentist.distance || 5,
      longitude: filter.lng || 0,
      latitude: filter.lat || 0,
      paymentPlans: filter.dentist.paymentPlans != undefined ? filter.dentist.paymentPlans : null,
      onlineBooking: filter.dentist.onlineBooking != undefined ? filter.dentist.onlineBooking : null,
      onlineChat: filter.dentist.onlineChat != undefined ? filter.dentist.onlineChat : null,
      nhsDoctor: filter.dentist.nhsDoctor != undefined ? filter.dentist.nhsDoctor : null,
      patientAcceptTypes: filter.dentist.patientAcceptTypes != undefined ? filter.dentist.patientAcceptTypes : null,
      take: 10,
      page: 1,
    };

    setParamData(param);
    sortParams(filter, navigate);
  }, [JSON.stringify(filter)]);

  useEffect(() => dispatch(getSearchData(paramData)), [JSON.stringify(paramData)]);
  useEffect(() => { setDataList(list); }, [list]);
  const onClose = () => { dispatch(setBookNowPopupData({})) };
  const onShowMoreClick = () => { setParamData({ ...paramData, page: paramData.page + 1 }) };

  return (
    <ResultsWrapper>
      <DrawMetaTags
        description={metaDescription}
        imageUrl={metaImageUrl}
        title={metaTitle}
        pageUrl={metaPageUrl}
        keywords={keywords}
      ></DrawMetaTags>
      <BreadcrumbContainer />
      <BookNowPopup onClose={onClose} show={showBookNow} />
      <RelatedSearch seltShowFilters={seltShowFilters} autoSelectData={autoSelectData} broadcastOpen={broadcastOpen} />
      <SC.ResultContainer>
        <SC.FilterOptionWrapper>
          <FilterOptions showFilters={showFilters} seltShowFilters={seltShowFilters} autoSelectData={autoSelectData} />
        </SC.FilterOptionWrapper>
        <SC.FilterResultWrapper>
          <FilterResult list={dataList} autoSelectData={autoSelectData} onShowMoreClick={onShowMoreClick} />
        </SC.FilterResultWrapper>
      </SC.ResultContainer>
      <Footer />
    </ResultsWrapper>
  );
};

export default memo(ListPage);
